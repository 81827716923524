exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dj-impreza-firmowa-js": () => import("./../../../src/pages/dj-impreza-firmowa.js" /* webpackChunkName: "component---src-pages-dj-impreza-firmowa-js" */),
  "component---src-pages-dj-impreza-urodzinowa-js": () => import("./../../../src/pages/dj-impreza-urodzinowa.js" /* webpackChunkName: "component---src-pages-dj-impreza-urodzinowa-js" */),
  "component---src-pages-dj-imprezy-okolicznosciowe-js": () => import("./../../../src/pages/dj-imprezy-okolicznosciowe.js" /* webpackChunkName: "component---src-pages-dj-imprezy-okolicznosciowe-js" */),
  "component---src-pages-dj-na-18-js": () => import("./../../../src/pages/dj-na-18.js" /* webpackChunkName: "component---src-pages-dj-na-18-js" */),
  "component---src-pages-dj-sylwester-js": () => import("./../../../src/pages/dj-sylwester.js" /* webpackChunkName: "component---src-pages-dj-sylwester-js" */),
  "component---src-pages-faq-dj-wesele-js": () => import("./../../../src/pages/faq-dj-wesele.js" /* webpackChunkName: "component---src-pages-faq-dj-wesele-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-wodzirej-na-wesele-js": () => import("./../../../src/pages/wodzirej-na-wesele.js" /* webpackChunkName: "component---src-pages-wodzirej-na-wesele-js" */)
}

